.datatable-demo .p-datatable-responsive .p-datatable-tbody > tr.p-datatable-row > td:last-child .p-column-title {
    display: none;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-details > .p-grid {
    border-radius: 3px;
    margin: 0.3em;
    text-align: center;
    padding: 2em 0 2.25em 0;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data .car-title {
    font-weight: 700;
    font-size: 20px;
    margin-top: 24px;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data .car-subtitle {
    margin: 0.25em 0 2em 0;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data button {
    margin-left: 0.5em;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data button:first-child {
    margin-left: 0;
}

.dataview-demo .p-dataview .car-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em;
}

.dataview-demo .p-dataview .car-details > div {
    display: flex;
    align-items: center;
}

.dataview-demo .p-dataview .car-details > div img {
    margin-right: 14px;
}

.dataview-demo .p-dataview .car-detail {
    padding: 0 1em 1em 1em;
    margin: 1em;
}

@media (max-width: 1024px) {
    .dataview-demo .p-dataview .car-details img {
        width: 75px;
    }
}

/*Own stiles*/
.ta-left {
    text-align: left;
}

.ta-right {
    text-align: right;
}

.ta-center {
    text-align: center;
}
.ta-justify {
    text-align: justify;
}
.mr-10 {
    margin-right: 10px;
}
.mt-6{
    margin-top: 6px;
}
.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-40 {
    margin-left: 40px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.padd-5 {
    padding: 5px;
}

.padd-40 {
    padding: 40px;
}

.padd-left-15 {
    padding-left: 15px;
}

.padd-left-50 {
    padding-left: 50px;
}

.padd-right-15 {
    padding-right: 15px;
}

.padd-right-50 {
    padding-right: 50px;
}

.padd-left-60 {
    padding-left: 60px;
}

.padd-right-60 {
    padding-right: 60px;
}

.row.card-image {
    margin: 25px -15px;
}

.row.card-image h4 {
    margin-top: 60px;
}

.card-benefits h2 {
    color: #000;
    margin-bottom: 50px;
}

.card-benefits img {
    margin-bottom: 20px;
}

.p-sm-1, .p-sm-2, .p-sm-3, .p-sm-4, .p-sm-5, .p-sm-6, .p-sm-7, .p-sm-8, .p-sm-9, .p-sm-10, .p-sm-11, .p-sm-12, .p-md-1, .p-md-2, .p-md-3, .p-md-4, .p-md-5, .p-md-6, .p-md-7, .p-md-8, .p-md-9, .p-md-10, .p-md-11, .p-md-12, .p-lg-1, .p-lg-2, .p-lg-3, .p-lg-4, .p-lg-5, .p-lg-6, .p-lg-7, .p-lg-8, .p-lg-9, .p-lg-10, .p-lg-11, .p-lg-12, .p-xl-1, .p-xl-2, .p-xl-3, .p-xl-4, .p-xl-5, .p-xl-6, .p-xl-7, .p-xl-8, .p-xl-9, .p-xl-10, .p-xl-11, .p-xl-12 {
    padding: 0.7em;
}

body {
    background-image: url(./img/bg_white.png);
}

.banner {
    background-image: url("./img/banner-bg.jpg");
    background-size: cover;
    padding: 130px 0 90px;
}

.banner2 {
    background-image: url("./img/income-from-card.jpg");
    background-size: cover;
    padding: 90px 0 0px;
}

.reduce-cost-with-e-wallet {
    background-image: url("./img/reduce-cost-with-e-wallet.jpg");
    background-size: cover;
    padding: 25px 0 40px;
}

.promioting-business {
    background-image: url("./img/promioting-business.jpg");
    background-size: cover;
    padding: 40px 0 50px;
}

.income-from-card {
    background-image: url("./img/income-from-card.jpg");
    background-size: cover;
    padding: 30px 0;
}

.card-benefits {
    background-image: url("./img/right-bevel.png");
    background-position: calc(100% + 300px) bottom;
    background-repeat: no-repeat;
    padding: 50px 0 70px;
    text-align: center;
}

.victoryPieArea {
    position: relative;
}

.victoryPieArea .victoryPieCenterLabel {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #DEDEDE;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.barController button.withDraw p {
    background: #f7ab32;
}

.barController button {
    display: inline-block;
    background: transparent;
    font-family: Hind Vadodara;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
    letter-spacing: 0;
}

.MuiSelect-icon,
.MuiFormLabel-root {
    color: #CCC !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
    height: 25px;
}

.MuiRadio-colorPrimary.Mui-checked {
    color: #d4bd74 !important;
}

.MuiTypography-body1 {
    color: #d4bd74 !important;
    font-weight: bold !important;
}

body .inputStyleBasic input,
body .inputStyleBasic textarea {
    height: 25px;
    width: 100%;
    padding-left: 15px;
    border: 1px solid #eeedff;
}

.MuiInput-underline:before {
    border-bottom: 1px solid #eeedff !important;
}

body .inputStyleBasic .Mui-disabled input,
body .inputStyleBasic .Mui-disabled textarea {
    border-bottom: 1px solid #8A8A8A;
}

.MuiFormControl-root.mcsInput.inputStyleBasic.MuiFormControl-fullWidth label {
    font-size: 20px;
    margin: 6px 0 0 -13px;
    display: block;
    width: 125%;
}

.MuiFormControl-root.MuiTextField-root.inputStyleBasic.MuiFormControl-fullWidth label {
    font-size: 20px;
}

.MuiFormControl-root.MuiTextField-root.inputStyleBasic.MuiFormControl-fullWidth label.Mui-disabled {
    color: #8A8A8A !important;
}

.buttonsChart button {
    display: inline-block;
    background: transparent;
    font-family: Hind Vadodara;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ECECEC;
    letter-spacing: 0;
}

.buttonsChart button p {
    display: inline-block;
    width: 15px;
    height: 15px;
    background: #FFFFFF;
    margin-right: 5px;
    transform: translateY(2px);
}

.buttonsChart button.withDraw p {
    background: #f7ab32;
}

.VictoryContainer text tspan {
    fill: #ECECEC !important;
}

body .inputStyleBasic input, body .inputStyleBasic textarea {
    color: #eeedff;
}

.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    color: #eeedff;
    padding: 10px 0 3px 15px;
}

.buyCoinWrapper .buyCoinSection {
    background: #fff;
    padding: 44px 80px;
    box-shadow: 0 0 1px #0a0d33;
    color: #000;
}

.IdVarificationForm .idCard {
    display: flex;
    background: #F0F5F8;
    border: 1px dashed #DCD9FF;
    box-sizing: border-box;
    border-radius: 1px;
    text-align: center;
    height: 215px;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.IdVarificationForm .idCard .icon {
    padding: 40px 0;
    flex: 1;
    display: flex;
}

.IdVarificationForm .idCard .icon img {
    object-fit: contain;
    margin: auto;
}

.IdVarificationForm .idCard .submitInfo {
    background: #FF4242;
    border-radius: 1px;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF !important;
    padding: 10px;
}

.inputSelectStyle {
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    font-size: 1.0714285714285714rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.1875em;
    align-items: center;
}

.inputSelectStyle .MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    color: #000;
    padding: 6px 24px 0px 7px;
}

.listInformation {
    text-align: left;
}

.listInformation li {
    line-height: 24px;
}

.uploadBtn {
    margin-top: 20px !important;
    background-color: transparent !important;
    color: #f7ab32 !important;
    line-height: 55px;
    left: 0;
    padding: 0;
    text-transform: capitalize;
    font-weight: 500;
    font-family: Hind Vadodara;
    position: relative;
    margin-bottom: 10px !important;
    border-radius: 4px;
    border: 1px solid #f7ab32 !important;
    margin-right: 0.25em !important;
}

.MuiButton-root.Mui-disabled.uploadBtn {
    color: #726846 !important;
    border: 1px solid #726846 !important;
    background-color: #333 !important;
}

.modal-dialog .MuiDialog-container .MuiDialog-paperScrollPaper {
    background-color: #00283A;
}

.MuiPaper-root.MuiStepper-root.MuiStepper-horizontal {
    background-color: #00283A;
}

.MuiStepLabel-label.MuiStepLabel-active {
    color: #f7ab32 !important;
    font-weight: bold !important;
}

.MuiStepLabel-label {
    color: #f7ab32 !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
    color: #d4bd74 !important
}

.MuiStepConnector-line {
    border-color: #d4bd74 !important;
}

.MuiStepIcon-root {
    color: #C3C3C3 !important;
}

.inputSelectStyle .MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    color: #C3C3C3 !important;
}

.rootDialog .scrollAble .modalPaper .modalInner .submitIdCardBody {
    text-align: center;
}

.rootDialog .scrollAble .modalPaper .modalInner .submitIdCardBody .formSubmitBtn {
    margin-bottom: 20px;
    background: #FF7510;
    border-radius: 1px;
    height: 65px;
    line-height: 65px;
    color: #fff;
    left: 0;
    padding: 0;
    text-transform: capitalize;
    font-weight: 700;
    font-family: Hind Vadodara;
    width: 120px;
    padding: 0 20px;
}

.rootDialog .scrollAble .modalPaper .modalInner .submitIdCardBody .multipleImgUpload .imageUpload {
    display: inline-block;
    margin: 0 33px;
}

.rootDialog .scrollAble .modalPaper .modalInner .submitIdCardBody .multipleImgUpload .imageUpload .imagePreview label {
    height: 128px;
    width: 100%;
    display: block;
    overflow: hidden;
}

.rootDialog .scrollAble .modalPaper .modalInner .submitIdCardBody .multipleImgUpload .uploadBtn {
    margin-top: 20px;
    background: #FF7510;
    border-radius: 1px;
    height: 55px;
    line-height: 55px;
    color: #fff;
    left: 0;
    text-transform: capitalize;
    font-weight: 500;
    font-family: Hind Vadodara;
    position: relative;
    margin-bottom: 10px;
    width: 120px;
    padding: 0 20px;
}

.MuiGrid-spacing-xs-32-26 > .MuiGrid-item-2 {
    padding: 16px !important;
}

.rootDialog .scrollAble .modalPaper .modalInner .submitIdCardBody .multipleImgUpload .uploadBtn input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
}

.p-hidden-accessible {
    /*position: inherit !important;*/
    height: auto !important;
}

.p-paginator .p-paginator-page, .p-paginator .p-paginator-pages, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last, .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-current {
    height: 2.5em !important;
}

.MuiRadio-root {
    color: #d4bd74 !important;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.MuiStepper-horizontal, .MuiStepper-vertical {
    background-color: transparent !important;
    border: 1px solid #d4bd74;
}

.goldText {
    color: #d4bd74;
}

.achBody {
    color: #d4bd74;
}

.achText {
    width: 200px;
}

.achInput {
    width: 250px;
}

.subtitleText {
    font-size: 15px;
}

.comment-text-area {
    float: left;
    width: 100%;
    height: auto;
}

.p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
}
.MuiIconButton-root {
    color: #FFFFFF !important;
}

.p-fluid .p-calendar {
    display: -ms-flexbox;
    display: flex;
}

.pd-0 {
    padding: 0px !important;
}

.pd-11 {
    padding: 11px !important;
}
.pd-9{
    padding: 9px !important;
}

/*AutoComplete.css*/
.p-autocomplete {
    display: inline-flex;
    position: relative;
}

.p-autocomplete-loader {
    position: absolute;
    top: 50%;
    margin-top: -.5rem;
}

.p-autocomplete-dd .p-autocomplete-input {
    flex: 1 1 auto;
    width: 1%;
}

.p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.p-autocomplete-dd .p-autocomplete-dropdown {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0px;
}

.p-autocomplete .p-autocomplete-panel {
    min-width: 100%;
}

.p-autocomplete-panel {
    position: absolute;
    overflow: auto;
}

.p-autocomplete-items {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.p-autocomplete-item {
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
}

.p-autocomplete-multiple-container {
    margin: 0;
    padding: 0;
    list-style-type: none;
    cursor: text;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.p-autocomplete-token {
    cursor: default;
    display: inline-flex;
    align-items: center;
    flex: 0 0 auto;
}

.p-autocomplete-token-icon {
    cursor: pointer;
}

.p-autocomplete-input-token {
    flex: 1 1 auto;
    display: inline-flex;
}

.p-autocomplete-input-token input {
    border: 0 none;
    outline: 0 none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
}

.p-fluid .p-autocomplete {
    display: flex;
}

.p-fluid .p-autocomplete-dd .p-autocomplete-input {
    width: 1%;
}

.p-input-icon-left > i:first-of-type {
    left: 0.5rem;
    color: #f7ab32;
}
.p-input-icon-left > .p-inputtext {
    padding-left: 2rem;
}
.p-input-icon-left>i, .p-input-icon-right>i {
    position: absolute;
    top: 50%;
    margin-top: -.5rem;
}

.p-input-signup {
    padding: 11px 25px;
}

.text-big {
    font-size: 16px;
    font-weight: bold;
    color: #f7ab32;
    cursor: pointer;
}

.imagenKYC:hover {
    border: 1px solid;
}

.landscape {
    height: 45px;
    width: 100%;
}
.landscape img {
    max-width: 100%;
    max-height: 100%;
}

a:hover {
    border-bottom: 1px solid;
 }

.slimDropdown > .p-dropdown-label.p-inputtext {
    padding: 1px 12px;
    border: 0px !important;
}

.small_button.p-button.p-button-icon-only .p-button-text {
    padding: 4px 30px;
}

@media (max-width: 600px) {
    .responsive-dialog {
        width: 80vw !important;
    }
}